import React, { Props } from "react"
import { Collapse, Input, Form, Icon, Button, Spin, InputNumber } from 'antd'
import { Helmet } from "react-helmet"
import SiteLayout from "../components/SiteLayout";
import axios from 'axios';

const Panel = Collapse.Panel;

enum Page {
    Loading = 0,
    Confirm = 1,
    ThankYou = 2,
    NoThankYou = 3,
    Error = 4
}

// @ts-ignore
import styles from './bekreft.module.scss';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
const API_URL = process.env.GATSBY_API_URL;

class BekreftForm extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            confirming: false,
            rejecting: false,
            buttonsActive: true,
            fieldsActive: true,
            page: Page.Loading,
            confirmationId: null,
            confirmationRequest: { address: { postCode: "", postalArea: "", streetAddress1: "" } }
        };

        this.onChangeInput = this.onChangeInput.bind(this);
        this.onChangeInputAddress = this.onChangeInputAddress.bind(this);
        this.handleReject = this.handleReject.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.lockForm = this.lockForm.bind(this);
        this.unlockForm = this.unlockForm.bind(this);
        this.lookupPostCodeInfo = this.lookupPostCodeInfo.bind(this);
        this.handleEmptyValues = this.handleEmptyValues.bind(this);


    }

    componentDidMount() {
        this.getConfirmation();
    }

    getConfirmation() {
        // Gets confirmation from api
        let url = window.location.href;
        let confirmationId = url.substr(url.lastIndexOf('/') + 1).replace('bekreft?', '').replace('?', '');

        //console.log("ConfirmationId: " + confirmationId);

        axios.get(API_URL + "/api/confirm?confirmationId=" + confirmationId).then(res => {
            if (res.status == 200) {
                this.setState({ page: Page.Confirm, confirmationRequest: res.data, confirmationId: confirmationId });
                //console.log(res.data);
            }
        }).catch(err => {
            this.setState({ page: Page.Error });
            //console.log(err);
        })
    }

    onChangeInput(e: React.ChangeEvent<HTMLInputElement>) {
        let fieldName = e.target.name;
        let value = e.target.value;

        const nextState = {
            ...this.state.confirmationRequest,
            [fieldName]: value,
        };
        this.setState({ confirmationRequest: nextState });

        this.handleEmptyValues(value);
    }

    onChangeInputAddress(e: React.ChangeEvent<HTMLInputElement>) {
        let fieldName = e.target.name;
        let value = e.target.value;

        const nextState = {
            ...this.state.confirmationRequest.address,
            [fieldName]: value,
        };
        this.setState({ confirmationRequest: { ...this.state.confirmationRequest, address: nextState } });

        if (fieldName == "postCode") {
            this.lookupPostCodeInfo(value);
        }

        this.handleEmptyValues(value);
    }

    handleEmptyValues(value: string) {
        // This is hacky but I don't want to spend more time on it and it seems to work...
        setTimeout(function () {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    if (this.state.confirmationRequest.address.postalArea !== "UGYLDIG POSTNUMMER") {
                        this.setState({ buttonsActive: true });
                    } else {
                        this.setState({ buttonsActive: false });
                    }
                } else {
                    this.setState({ buttonsActive: false });
                }
            });
        }.bind(this), 200);
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.lockForm(true);

        axios.post(API_URL + "/api/confirm", { confirmationId: this.state.confirmationId, updatedInformation: this.state.confirmationRequest, confirmed: true }).then(res => {
            if (res.status == 200) {
                this.setState({ page: Page.ThankYou });
            }
        }).catch(err => {
            this.setState({ page: Page.Error });
        })
    }

    handleReject = (e) => {
        e.preventDefault();
        this.lockForm(false);
        axios.post(API_URL + "/api/confirm", { confirmationId: this.state.confirmationId, confirmed: false }).then(res => {
            if (res.status == 200) {
                this.setState({ page: Page.NoThankYou });
            }
        }).catch(err => {
            this.setState({ page: Page.Error })
        })
    }

    lookupPostCodeInfo(postCode: string) {
        axios.get(API_URL + "/api/postcode?postCode=" + postCode).then(res => {
            if (res.status == 200) {
                const nextState = {
                    ...this.state.confirmationRequest.address,
                    postalArea: res.data.postalArea
                };
                this.setState({ confirmationRequest: { ...this.state.confirmationRequest, address: nextState } });
            }
        }).catch(err => {
            const nextState = {
                ...this.state.confirmationRequest.address,
                postalArea: "UGYLDIG POSTNUMMER"
            };
            this.setState({ confirmationRequest: { ...this.state.confirmationRequest, address: nextState } });
        })
    }

    lockForm(confirming: boolean) {
        this.setState({ buttonsActive: false, fieldsActive: false, confirming: confirming, rejecting: !confirming });
    }

    unlockForm() {
        this.setState({ buttonsActive: true, fieldActive: true, confirming: false, rejecting: false });
    }


    renderConfirmPage() {
        const { getFieldDecorator } = this.props.form;

        return (
            <div>
                <h1>Bekreft din reservasjon</h1>
                <div className={styles.introtext}>Ta en titt på informasjonen vi har registrert og korriger dersom&nbsp;nødvendig. Når du har sjekket at alt ser riktig ut trykker du på knappene for om du vil bekrefte eller&nbsp;avkrefte.
                    <Form hideRequiredMark={true} onSubmit={this.handleSubmit} className={styles.confirmform} layout="horizontal" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                        <Form.Item className={styles.compactfield} label="Fornavn">
                            {getFieldDecorator('firstname', {
                                rules: [{ required: true, message: 'Du må fylle inn fornavn' }],
                                initialValue: this.state.confirmationRequest.firstname
                            })(
                                <Input autoComplete="off" disabled={!this.state.fieldsActive} name="firstname" size="large" prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Fornavn" onChange={this.onChangeInput} />
                            )}
                        </Form.Item>

                        <Form.Item
                            label="Etternavn"
                        >
                            {getFieldDecorator('lastname', {
                                rules: [{ required: true, message: 'Du må fylle inn etternavn' }],
                                initialValue: this.state.confirmationRequest.lastname
                            })(
                                <Input disabled={!this.state.fieldsActive} name="lastname" size="large" prefix={<Icon type="" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Etternavn" onChange={this.onChangeInput} />
                            )}
                        </Form.Item>

                        <Form.Item
                            label="Telefonnummer"
                        >
                            {getFieldDecorator('phoneNumber', {
                                rules: [{ required: true, message: 'Du må fylle inn telefonnummer' }],
                                initialValue: this.state.confirmationRequest.phoneNumber
                            })(
                                <Input disabled={!this.state.fieldsActive} name="phoneNumber" size="large" prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Telefonnummer" onChange={this.onChangeInput} />
                            )}
                        </Form.Item>


                        <Form.Item className={styles.compactfield}
                            label="Addresse"
                        >
                            {getFieldDecorator('streetAddress1', {
                                rules: [{ required: true, message: 'Du må fylle inn adresse' }],
                                initialValue: this.state.confirmationRequest.address.streetAddress1
                            })(
                                <Input disabled={!this.state.fieldsActive} name="streetAddress1" size="large" prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Gateadresse" onChange={this.onChangeInputAddress} />
                            )}
                        </Form.Item>

                        <Form.Item className={styles.compactfield}
                            label="Postnummer"
                        >
                            {getFieldDecorator('postCode', {
                                rules: [{ required: true, message: 'Du må fylle inn gyldig postnummer', min: 4, max: 4 }],
                                initialValue: this.state.confirmationRequest.address.postCode
                            })(
                                <Input type="number" disabled={!this.state.fieldsActive} name="postCode" size="large" prefix={<Icon type="" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Postnummer" onChange={this.onChangeInputAddress} />
                            )}
                        </Form.Item>

                        <Form.Item
                            required
                            help=""
                            label="Sted"
                        >
                            <Input disabled={true} name="postalArea" size="large" prefix={<Icon type="" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Stedsnavn" value={this.state.confirmationRequest.address.postalArea} />
                        </Form.Item>

                        <br />
                        <div className={styles.introsubtext}>Dette ser riktig ut og jeg vil ha&nbsp;en&nbsp;Oss-brikke</div>
                        <Button disabled={!this.state.buttonsActive} loading={this.state.confirming} type="primary" htmlType="submit" className={styles.confirmbutton} size="large">
                            Jepp!
                            </Button>

                        <Button disabled={!this.state.buttonsActive} loading={this.state.rejecting} type="danger" onClick={this.handleReject} className={styles.rejectbutton} size="large">
                            Nei, takk!
                            </Button>
                    </Form>
                </div>
            </div>
        )
    }

    renderLoadingPage() {
        return (
            <div>
                <h1>Leter etter reservasjon</h1>
                <div className={styles.introtext}>Vent litt mens vi henter frem reservasjonen.<br /><br /><Spin indicator={antIcon} /></div>
            </div>
        )
    }


    renderThankYouPage() {
        window.scrollTo(0, 0);
        return (
            <div>
                <h1>Takk for din bekreftelse</h1>
                <div className={styles.introtext}>
                    Din reservasjon er nå lagret og vi vil gi deg beskjed så fort en Oss-brikke er på vei til deg.
                </div>
                <div style={{textAlign: 'center'}}>I mellomtiden oppforderer vi dere alle til å kontakte nettselskapet i ditt område og be om aktivering av din HAN-port. 
                Dette kan ta alt fra dager til uker, varierer veldig fra nettselskap til nettselskap. Denne forespørselen må nemlig komme fra deg som forbruker. 
                Les mer om HAN-porten på <a href="https://www.nve.no/nytt-fra-nve/nyheter-reguleringsmyndigheten-for-energi/slik-kan-du-faa-tilgang-til-sanntidsinformasjon-om-ditt-eget-stroemforbruk/" target="_new">NVE sine sider</a>.
                </div><p><b>Viktig at du samtykker til å prate med oss under Min Profil i appen, på den måten vil vi kunne hjelpe deg best mulig</b> </p><p>Ha en fortsatt opp&nbsp;dag!</p>
            </div>
        )
    }

    renderNoThankYouPage() {
        window.scrollTo(0, 0);
        return (
            <div>
                <h1>Det er notert!</h1>
                <div className={styles.introtext}>Din reservasjon er nå slettet og du vil <u>ikke</u> få en Oss-brikke i posten. Ha en fortsatt topp&nbsp;dag!</div>
            </div>
        )
    }

    renderErrorPage() {
        window.scrollTo(0, 0);
        return (
            <div>
                <h1>Utløpt eller ugyldig reservasjon</h1>
                <div className={styles.introtext}>Denne reservasjonen er utløpt eller ugyldig. Ta kontakt med oss dersom dette er feil. <a href="mailto:hei@oss.no">hei@oss.no</a> eller tekstboblen nede i høyre hjørne.</div>
            </div>
        )
    }

    renderPage() {
        if (this.state.page == Page.Loading) {
            return this.renderLoadingPage();
        }
        if (this.state.page == Page.Confirm) {
            return this.renderConfirmPage();
        }
        if (this.state.page == Page.ThankYou) {
            return this.renderThankYouPage();
        }
        if (this.state.page == Page.NoThankYou) {
            return this.renderNoThankYouPage();
        }
        if (this.state.page == Page.Error) {
            return this.renderErrorPage();
        }
    }

    render() {
        return (
            <SiteLayout>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Oss | Bekreft</title>
                    <script>{`
                    {
            window.intercomSettings = {
                app_id: "wpsqtjpa"
            };
            }`}
                    </script>
                    <script>{`
                    {
            (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',intercomSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Intercom=i;function l(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/wpsqtjpa';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})()
            }`}
                    </script>
                </Helmet>
                <div className={styles.smallcontainer}>
                    {this.renderPage()}
                </div>
            </SiteLayout >
        );
    }
}

export default class Bekreft extends React.Component<any, any> {
    render() {
        const BekreftFormComponent = Form.create()(BekreftForm);
        return (<BekreftFormComponent />);
    }
}